import { Formik } from "formik";
import React, { useEffect, useState } from "react"; 
import Layout from "../components/Layout";
import * as Yup from "yup";
import { Box, Button, TextField } from "@material-ui/core";
import { requestHandler } from "../utils/utils";
import { API_URL } from "../constants/constants";
import { toast , ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Visibility, VisibilityOff } from '@material-ui/icons';
const styles = require("../styles/userSettings.module.css"); 
import { navigate } from 'gatsby';

const resetPassword = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const [ qParam , setQParam ] = useState([""]);
    const token = "";
    if(typeof window !== 'undefined') {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get("token") ? queryParams.get("token") : "";
    }
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    let icon:any ;
    if (passwordShown ==true) {
        icon = <Visibility className = {styles.passwordIcon} onClick={() => togglePasswordVisiblity()} />;
    } else if (passwordShown == false) {
        icon = <VisibilityOff className = {styles.passwordIcon} onClick={() => togglePasswordVisiblity()} />;
    }

    const callLogoutAPI = async () => {
        try {
            const result:any = await requestHandler(API_URL + "developer/logout" , null, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "POST")
            if(result.status === "success") 
            {
                toast.success(result.message); 
                localStorage.setItem("isLoggedIn", "false");
                localStorage.setItem("userData", "");
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                navigate("/")
            }
            if(result.status === "failed") 
            {
                toast.error(result.errorMessage); 
            }      
        }
        catch (error) {
            // Sentry.captureException(error);
            toast.error(error.errorMessage); 
            console.log("error", error.errorMessage);
        }
    }
    
    return (
        <Layout>
            <div className = {styles.userSetPageWrapper}>
                <div className = {styles.userReSetPage}>
                    <div className = {styles.userSetTitle}>
                        Change Your Password
                    </div>
                    <div className = {styles.userSetBody}>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                npwd: "" , 
                            }}
                            validationSchema={Yup.object().shape({
                                npwd: Yup.string()
                                    .matches(
                                        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                                        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
                                    )
                                    .required("New Password is required"),
                            })}
                            onSubmit={async (values: any) => {
                                try {
                                    const data = {
                                        "token": token,
                                        "password": values.npwd
                                    }
                                    const result:any = await requestHandler(API_URL + "developer/reset-password" , data, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "PUT")
                                    if(result.status === "success") 
                                    {
                                        toast.success(result.message); 
                                        callLogoutAPI();
                                    }
                                    if(result.status === "failed") 
                                    {
                                        toast.error(result.errorMessage);
                                    }      
                                }
                                catch (error) {
                                    // Sentry.captureException(error);
                                    toast.error(error.errorMessage); 
                                    console.log("error", error.errorMessage);
                                }
                            }}>

                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box my={3}>
                                        <TextField
                                            error={Boolean(touched.npwd && errors.npwd)}
                                            fullWidth
                                            helperText={touched.npwd && errors.npwd}
                                            label="New Password"
                                            margin="normal"
                                            name="npwd"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type={passwordShown ? "text" : "password"}
                                            value={values.npwd}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: icon
                                            }}
                                        />
                                    </Box>
                                    <Box my={2}>
                                        <Button
                                            // className = {styles.loginBtn}
                                            color="primary"
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </Layout>
        
    )
}

export default resetPassword;